import { useContext } from "react";
import cashewFruits from "../../assets/images/Cashew-fruits.jpeg";
import { Row, Col, Container } from "reactstrap";
import { LanguageContext } from "../../LanguageProvider";

const Story = () => {
  const { language } = useContext(LanguageContext);
  const content = {
    English: {
      title: "STORY OF THE CASHEW FRUIT",
      description: ` Once upon a time, there was a beauty contest among the fruits of the world. Each fruit made a speech, some about their beauty, some of their values and some of their importance in the world. The cashew fruit came on stage and said, “I am the cashew fruit, and this is my partner, the cashew nut”, the crowd looked in awe as the fruit continued. “The cashew nut is attached to me and grows outside of me. I feed the nut with my delicious nutrients. Among all the fruits in the world, I am special, bold, and beautiful. My skin is bright with warm and cool colours such as green, yellow, orange, and red, my partner the nut is grey. Those who know me love me. Those who see me admire me. Those who have heard of me adore me. Those who taste me always hunger for more because I am soft, juicy and sweet. I am a super fruit! rich in vitamins and antioxidants! The world is just learning of my benefits to humanity. I am the cashew fruit, the golden super fruit made into cashew fruit juice to quench the thirst of the world!!”.`,
    },
    Finnish: {
      title: "cashewhedelmän tarina",
      description: `Olipa kerran kaikkien maailman hedelmien kauneuskilpailu. Jokainen hedelmä sai pitää puheen kehuakseen hyviä puoliaan, jotkut korostivat kauneuttaan, toiset arvoaan ja kolmannet tärkeyttään merkitystään maailmalle. 
      Tuli cashewhedelmän vuoro astua lavalle. Hän aloitti: " Olen cashewhedelmä ja tässä on ystäväni cashewpähkinä". Yleisö katsoi kaksikkoa ihmeissään ja hedelmä jatkoi: "Cashewpähkinä on osa minua ja kasvaa kanssani, kun ruokin häntä herkullisilla ravinteillani. Erotun kaikkien maailman hedelmien joukosta, koska olen erityinen, kaunis ja rohkea! Kuoreni on kirkkaan vihreä, keltainen, oranssi ja punainen. Ystäväni pähkinä on harmaa. Ne jotka jo tuntevat minut, rakastavat minua. Ne jotka tapaavat minut, ihailevat minua. Ne jotka kuulevat minusta, haluavat tavata minut. Ne jotka maistavat minua, haluavat aina lisää sillä olen pehmeä, makea ja mehukas. Olen oikeaa superruokaa, olen täynnä vitamiineja ja antioksidantteja! Maailma ei vielä edes tiedä mitä kaikkea voin tehdä ihmisten hyväksi. Minä olen cashewhedelmä, superhedelmä jonka mehu täyttää jokaisen janoisen toiveet!"`,
    },
  };
  return (
    <Container>
      <Row>
        <Col>
          <h2 className="text-center" id="intro">
            {language === "Finnish"
              ? content.Finnish.title
              : content.English.title}
          </h2>
        </Col>
      </Row>
      <Row className="story-section mt-5 mb-5" id="intro">
        <Col className="text-center text-md-start">
          <div className="text-content">
            <p className="text-justify">
              {language === "Finnish"
                ? content.Finnish.description
                : content.English.description}
            </p>
            <img src={cashewFruits} alt="Camerass cashew juice" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Story;
