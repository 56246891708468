import { BrowserRouter, RouterProvider, Routes, Route } from "react-router-dom";
import {useEffect } from "react";
import { routes } from "./routes";
import "./App.css";
import Layout from "./components/Layout";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Product from "./pages/product/Product";
import Story from "./pages/story/Story";
import Contacts from "./pages/contacts/Contacts";
import { LanguageProvider } from "./LanguageProvider";

function App() {
  useEffect(() => {
    const url = window.location.origin;
    if (!url.includes("localhost") && !url.includes("https")) {
      window.location = `https:${url.split(":")[1]}`;
    }
  });
  // return <RouterProvider router={routes} />;
  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="products" element={<Product />} />
            <Route path="stories" element={<Story />} />
            <Route path="contacts" element={<Contacts />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default App;
