import React from "react";
import Menu from "./Navbar";
import styles from "./Header.module.scss";
import { Row, Col } from "reactstrap";
const Header = () => (
  <Row>
    <Col>
      <header className={styles.header}>
        <Menu />
      </header>
    </Col>
  </Row>
);

export default Header;
