import React, { createContext, useState, useEffect } from "react";
const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("English");
  useEffect(() => {
    const savedUser = localStorage.getItem("language") || null;
    if (savedUser) {
      setLanguage(JSON.parse(savedUser));
    }
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("language", JSON.stringify(lang));
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
