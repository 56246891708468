import React, { useState, useContext } from "react";
import camarassLogoSVG from "../../assets/images/image2vector.svg";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import "./navbar.scss";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../LanguageProvider";
import { FaGlobe } from "react-icons/fa";

const navbar = {
  English: ["About", "Products", "Stories", "Contacts"],
  Finnish: ["Tieto", "Tuotteet", " Tarina", "Yhteyttä"],
};

const Menu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const { language, changeLanguage } = useContext(LanguageContext);

  return (
    <Navbar className="navbar navbar-expand-md navbar-light text-uppercase fixed-top">
      <NavbarBrand>
        <Link to="/">
          <img src={camarassLogoSVG} alt="" className="logo" />
        </Link>
        <br />
        <em>Drinking it is loving it</em>
        <span className="fw-bold text-secondary">
          <i className="bi bi-book-half"></i>
        </span>
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} className="me-2" />
      <Collapse
        isOpen={!collapsed}
        navbar
        className="collapse navbar-collapse justify-content-end align-center"
        id="main-nav"
      >
        <Nav navbar className="navbar-nav">
          <NavItem className="nav-item">
            <Link
              to="/about"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              {language === "Finnish" ? "Tieto" : "About"}
            </Link>
          </NavItem>
          <NavItem className="nav-item">
            <Link
              tag={Link}
              to="/products"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              {language === "Finnish" ? "Tuotteet" : "Products"}
            </Link>
          </NavItem>
          <NavItem className="nav-item">
            <Link
              to="/stories"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              {language === "Finnish" ? "Tarina" : " Stories"}
            </Link>
          </NavItem>
          <NavItem className="nav-item">
            <Link
              to="/contacts"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              {language === "Finnish" ? "Yhteyttä" : " Contacts"}
            </Link>
          </NavItem>
          <div className="language-select">
            {/* <FaGlobe /> */}
            <select
              className="custom-select"
              value={language}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <option value="English">EN</option>
              <option value="Finnish">FI</option>
            </select>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
};
export default Menu;
