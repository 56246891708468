
/* export const locations = [
 
  {
    supermarket: "K- Supermarket Kamppi",
    streetName: "Urho Kekkosen katu 1",
    zipcode: "00100",
    city: "Helsinki",
    center: {
      lat: "60.1690911", // No lat provided in the original text
      lng: "24.9331156", // No lng provided in the original text
    },
  },
  {
    supermarket: "K- Supermarket Masi",
    streetName: "Pallaksentie 2",
    zipcode: "00970",
    city: "Helsinki",
    center: {
      lat: "54.118382", // No lat provided in the original text
      lng: "12.143130", // No lng provided in the original text
    },
  },
  {
    supermarket: "K- Supermarket Mustapekka",
    streetName: "Mäkitorpantie 1",
    zipcode: "00620",
    city: "Helsinki",
    center: {
      lat: "60.221630", // No lat provided in the original text
      lng: "24.948219", // No lng provided in the original text
    },
  },
  {
    supermarket: "K-Supermarket Viikki",
    streetName: "Latokartanonkaari 23",
    zipcode: "00790",
    city: "Helsinki",
    center: {
      lat: "60.233730", // No lat provided in the original text
      lng: "25.032350", // No lng provided in the original text
    },
  },
  {
    supermarket: "Taj Mehal Afro-Asian Market",
    streetName: "Läkkisepänkuja 3",
    zipcode: "",
    city: "Espoo",
    center: {
      lat: "60.220509", // No lat provided in the original text
      lng: "24.813000", // No lng provided in the original text
    },
  },
  {
    supermarket: "K-Supermarket Kauppatori",
    streetName: "Lapinkävijäntie 7",
    zipcode: "96100",
    city: "Rovaniemi",
    center: {
      lat: "66.500839", // No lat provided in the original text
      lng: "25.714830", // No lng provided in the original text
    },
  },
  {
    supermarket: "K-Supermarket Ounasvaara",
    streetName: "Ounasvaarantie 15",
    zipcode: "96400",
    city: "Rovaniemi",
    center: {
      lat: "66.497932", // No lat provided in the original text
      lng: "25.7541737", // No lng provided in the original text
    },
  },
  {
    supermarket: "K-Supermarket Rinteenkulma",
    streetName: "Koskikatu 25",
    zipcode: "96200",
    city: "Rovaniemi",
    center: {
      lat: "66.5028719", // No lat provided in the original text
      lng: "25.7253716", // No lng provided in the original text
    },
  },
  {
    supermarket: "K-Citymarket Palokka",
    streetName: "Saarijärventie 50-52",
    zipcode: "40270",
    city: "Jyväskylä",
    center: {
      lat: "66.5", // No lat provided in the original text
      lng: "25.71667", // No lng provided in the original text
    },
  },
  {
    supermarket: "K-Supermarket Ratina",
    streetName: "Vuolteenkatu 1",
    zipcode: "33100",
    center: {
      lat: "61.4933393", // No lat provided in the original text
      lng: "23.7679551", // No lng provided in the original text
    },
    city: "Tampere",
  },
] */

/* const arr = []
for (const l of locations) {
  let label = `${l.city}, ${l.streetName}, ${l.supermarket}`
  let { lat, lng } = l.center
  arr.push({ label, value: `${lat} ${lng}` })
}
 */

export const options = [
  {
    label: "Espoo, Läkkisepänkuja 3, Taj Mehal Afro-Asian Market",
    value: "60.220509 24.813000",
  },
  {
    label: "Helsinki, Urho Kekkosen katu 1, K- Supermarket Kamppi",
    value: "60.1690911 24.9331156",
  },
  {
    label: "Helsinki, Pallaksentie 2, K- Supermarket Masi",
    value: "54.118382 12.143130",
  },
  {
    label: "Helsinki, Mäkitorpantie 1, K- Supermarket Mustapekka",
    value: "60.221630 24.948219",
  },
  {
    label: "Helsinki, Latokartanonkaari 23, K-Supermarket Viikki",
    value: "60.233730 25.032350",
  },
  {
    label: "Rovaniemi, Lapinkävijäntie 7, K-Supermarket Kauppatori",
    value: "66.500839 25.714830",
  },
  {
    label: "Rovaniemi, Ounasvaarantie 15, K-Supermarket Ounasvaara",
    value: "66.497932 25.7541737",
  },
  {
    label: "Rovaniemi, Koskikatu 25, K-Supermarket Rinteenkulma",
    value: "66.5028719 25.7253716",
  },
  {
    label: "Jyväskylä, Saarijärventie 50-52, K-Citymarket Palokka",
    value: "66.5 25.71667",
  },
  {
    label: "Tampere, Vuolteenkatu 1, K-Supermarket Ratina",
    value: "61.4933393 23.7679551",
  },
]



export const locations = [
  {
    supermarket: "Taj Mehal Afro-Asian Market",
    zone: "",
    streetName: "Läkkisepänkuja 3",
    zipcode: "00970",
    city: "Espoo",
    center: { lat: 60.219081, lng: 24.651916 }
  },
  {
    supermarket: "K- Supermarket",
    zone: "Kamppi",
    streetName: "Urho Kekkosen katu 1",
    zipcode: "00100",
    city: "Helsinki",
    center: { lat: 60.169095, lng: 24.933978 }
  },
  {
    supermarket: "K- Supermarket",
    zone: "Masi",
    streetName: "Pallaksentie 2",
    zipcode: "00970",
    city: "Helsinki",
    center: { lat: 60.224197, lng: 24.871360 }
  },
  {
    supermarket: "K- Supermarket",
    zone: "Mustapekka",
    streetName: "Mäkitorpantie 1",
    zipcode: "00620",
    city: "Helsinki",
    center: { lat: 60.196122, lng: 24.932013 }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Viikki",
    streetName: "Latokartanonkaari 23",
    zipcode: "00790",
    city: "Helsinki",
    center: { lat: 60.228764, lng: 25.007509 }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Kauppatori",
    streetName: "Lapinkävijäntie 7",
    zipcode: "96100",
    city: "Rovaniemi",
    center: { lat: 66.503372, lng: 25.715672 }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Ounasvaara",
    streetName: "Ounasvaarantie 15",
    zipcode: "96400",
    city: "Rovaniemi",
    center: { lat: 66.503986, lng: 25.751525 }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Rinteenkulma",
    streetName: "Koskikatu 25",
    zipcode: "96200",
    city: "Rovaniemi",
    center: { lat: 66.503776, lng: 25.726259 }
  },
  {
    supermarket: "K-Citymarket",
    zone: "Palokka",
    streetName: "Saarijärventie 50-52",
    zipcode: "40270",
    city: "Jyväskylä",
    center: { lat: 62.237760, lng: 25.805445 }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Ratina",
    streetName: "Vuolteenkatu 1",
    zipcode: "33100",
    city: "Tampere",
    center: { lat: 61.500789, lng: 23.761864 }
  },
  {
    supermarket: "Aasian Säästö Market",
    zone: "",
    streetName: "Rovakatu 19",
    zipcode: "96200",
    city: "Rovaniemi",
    center: { lat: 66.497912, lng: 25.714628 }
  },
  {
    supermarket: "K-Market",
    zone: "Ounasmetsä",
    streetName: "Kivikaudentie 1",
    zipcode: "96400",
    city: "Rovaniemi",
    center: { lat: 66.503042, lng: 25.759674 }
  },
  {
    supermarket: "Da Zhong Hua Restaurant",
    zone: "",
    streetName: "Koskikatu 43",
    zipcode: "96100",
    city: "Rovaniemi",
    center: { lat: 66.501369, lng: 25.721572 }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Vuosaari",
    streetName: "Tyynylaavantie 5",
    zipcode: "00980",
    city: "Helsinki",
    center: { lat: 60.214945, lng: 25.136139 }
  },
  {
    supermarket: "K-Citymarket",
    zone: "Seppälä",
    streetName: "Vasarakatu 29",
    zipcode: "40320",
    city: "Jyväskylä",
    center: { lat: 62.226445, lng: 25.712283 }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Kallio",
    streetName: "Hämeentie 16",
    zipcode: "00530",
    city: "Helsinki",
    center: { lat: 60.182616, lng: 24.948030 }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Ruokakippari",
    streetName: "Laivalahdenkaari 29",
    zipcode: "00810",
    city: "Helsinki",
    center: { lat: 60.195690, lng: 25.097354 }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Aurinkolahti",
    streetName: "Leikosaarentie 48",
    zipcode: "00990",
    city: "Helsinki",
    center: { lat: 60.193719, lng: 25.140746 }
  },
  {
    supermarket: "Vii Voan Market",
    zone: "Hakaniemi",
    streetName: "Hameentie 3",
    zipcode: "00530",
    city: "Helsinki",
    center: { lat: 60.179948, lng: 24.947966 }
  },
  {
    supermarket: "NARI",
    zone: "Itakesku",
    streetName: "Kastelholmantie 2",
    zipcode: "00900",
    city: "Helsinki",
    center: { lat: 60.207546, lng: 25.075212 }
  },
  {
    supermarket: "MINIPRIX",
    zone: "Malmi",
    streetName: "Vihernienkatu 1",
    zipcode: "00700",
    city: "Helsinki",
    center: { lat: 60.252931, lng: 25.004259 }
  },
  {
    supermarket: "MANGO MARKET",
    zone: "Itakeskus",
    streetName: "Kastelholmantie 2",
    zipcode: "00900",
    city: "Helsinki",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Kaijonharju",
    streetName: "Karhuojantie 2",
    zipcode: "90570",
    city: "Oulu",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Pekuri",
    streetName: "Kauppurienkatu 10",
    zipcode: "90100",
    city: "Oulu",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Ritaharju",
    streetName: "Rita-aukiontie 2",
    zipcode: "90540",
    city: "Oulu",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Toppila",
    streetName: "Satamatie 26",
    zipcode: "90520",
    city: "Oulu",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Välivainio",
    streetName: "Revonkuja 2",
    zipcode: "90530",
    city: "Oulu",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Antinkatu",
    streetName: "Antinkatu 19",
    zipcode: "",
    city: "Pori",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Nekala",
    streetName: "Viinikankatu 40",
    zipcode: "33800",
    city: "Tampere",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Hämeenkatu",
    streetName: "Hämeenkatu 4",
    zipcode: "33100",
    city: "Tampere",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Kaukajärvi",
    streetName: "Juvankatu 14",
    zipcode: "33710",
    city: "Tampere",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Kuninkaankulma",
    streetName: "Kuninkaankatu 14-16",
    zipcode: "33210",
    city: "Tampere",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Basilika",
    streetName: "Kaskikylänkuja 2",
    zipcode: "04130",
    city: "Sipoo",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Hakunila",
    streetName: "Laukkarinne 4",
    zipcode: "01200",
    city: "Vantaa",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-market",
    zone: "Tuira",
    streetName: "Valtatie 61",
    zipcode: "90500",
    city: "Oulu",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "Hot Curry Market",
    zone: "",
    streetName: "Pitkäkatu 40",
    zipcode: "65100",
    city: "Vaasa",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "Idis Market",
    zone: "",
    streetName: "Syöttäjänkatu 1",
    zipcode: "40520",
    city: "Jyväskylä",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket",
    zone: "Petonen",
    streetName: "Jalkasenkatu 5",
    zipcode: "70820",
    city: "Kuopio",
    center: { lat: "latitude_value", lng: "longitude_value" }
  },
  {
    supermarket: "K-Supermarket Veljmies",
    zone: "",
    streetName: "Puijonkatu 19",
    zipcode: "70100",
    city: "Kuopio",
    center: { lat: "latitude_value", lng: "longitude_value" }
  }
]

