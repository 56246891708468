import cashewJuiceCans from "../../assets/images/cashew_juice_cans-2.png";
import { Row, Col, Container } from "reactstrap";

const Home = () => (
  <Container>
    <Row>
      <Col>
        <h2 className="text-center hero-title">Camarass Cashew Juice</h2>
      </Col>
    </Row>
    <Row className="hero-section justify-content-center">
      <Col className="text-center" md={6}>
        <img
          src={cashewJuiceCans}
          alt="Camerass cashew juice"
          className="hero-image"
        />
      </Col>
    </Row>
  </Container>
);

export default Home;
