import React, { useContext } from "react";
import { Row, Col, Container } from "reactstrap";
import { LanguageContext } from "../../LanguageProvider";

const Contacts = () => {
  const { language } = useContext(LanguageContext);
  const content = {
    English: {
      title: "Contact",
      description:
        "If you need Camarass Cashew Juice in your Supermarket, contact us:",
    },
    Finnish: {
      title: "Yhteyttä",
      description:
        "Jos tarvitset Camarass Cashew Mehua supermarketissasi, ota meihin yhteyttä:",
    },
  };

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="text-center">
            {language === "Finnish"
              ? content.Finnish.title
              : content.English.title}
          </h2>
        </Col>
      </Row>
      <Row className="contacts-section">
        <Col>
          <div className="text-content">
            <p className="text-justify">
              {language === "Finnish"
                ? content.Finnish.description
                : content.English.description}
            </p>
            <div>
              <pre>
                <address>
                  Kuntotie 2 <br />
                  96400 Rovaniemi <br />
                  Finland <br />
                  Tel: <a href="tel:+358505205552">+358505205552</a> <br />
                  E-mail:{" "}
                  <a href="mailto:info@camarass.com" title="info@camarass.com">
                    info@camarass.com
                  </a>
                  <br />
                </address>
              </pre>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contacts;
