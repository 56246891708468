import { Row, Col } from "reactstrap";
import { useContext } from "react";
import { LanguageContext } from "../../LanguageProvider";

export const About = () => {
  const { language } = useContext(LanguageContext);
  const content = {
    English: {
      title: "About US",
      description: `Camarass started in the classrooms of the University of Lapland in Rovaniemi. The company strives to create a global awareness of the nutritional value of cashew fruit whilst simultaneously reducing food waste. Camarass pursues ecological food sensibility by processing nutritious cashew fruits through a partnership with manufacturers in the areas where the tree grows. More than 90% of the cashew fruit is thrown away and left to waste since the prized cashew nut, a popular snack, is enjoyed around the world. Each cashew nut was once part of a cashew fruit which was most likely thrown away. The fruit itself has a high content of vitamin C and antioxidants and it is Camarass’ aim to empower food sustainability by reducing the waste of cashew fruit.`,
    },
    Finnish: {
      title: "Tietoa meistä",
      description: `Camarass sai alkunsa Lapin yliopiston luokkahuoneissa Rovaniemellä. Yritys haluaa levittää tietoa cashewhedelmän ravintorikkaudesta ja samalla vähentää ruokahävikkiä. 
      Camarassin tuotteet valmistetaan yhteistyössä paikallisten tuottajien kanssa kunnioittaen ekologisia arvoja. Yli 90% maailmassa kasvatetuista cashewhedelmistä päätyy jätteeksi cashewpähkinöiden tuotannon sivutuotteena. Jokainen cashewpähkinä on kasvanut osana cashewhedelmää, ja mitä todennäköisimmin tämä hedelmä on heitetty pois. 
      Cashewhedelmä sisältää runsaasti C-vitamiinia ja antioksidantteja. Camarassin tavoitteena on toteuttaa ekologisia ja kestäviä periaatteita vähentämällä cashewhedelmän hävikkiä.`,
    },
  };
  return (
    <Row className="about-section">
      <Col>
        <h2 className="text-center">
          {language === "Finnish"
            ? content.Finnish.title
            : content.English.title}
        </h2>
        <div className="text-content">
          <p className="text-justify">
            {language === "Finnish"
              ? content.Finnish.description
              : content.English.description}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default About;
