import React, { useCallback, useEffect, useState } from "react"
import camarassCashewCan from "../../assets/images/camarass_cashew_can.png"
import { Row, Col, Container, Input } from "reactstrap"
import { useContext } from "react"
import { LanguageContext } from "../../LanguageProvider"
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"
import { options, locations as data } from "../../utils/locations"

const API_KEY = "AIzaSyBMoETGFYCCP_dDWSN3HB9d6KAwPsyNhKA"

const containerStyle = {
  width: "400px",
  height: "400px",
}

const MapContainer = ({ coordiante }) => {
  console.log(coordiante)
  const [lat, lng] = coordiante.split(" ").map((val) => Number(val))
  console.log(lat, lng)

  const center = {
    lat,
    lng,
  }
  const mapStyles = {
    height: "400px",
    width: "100%",
  }

  const mapOptions = {
    zoomControl: true,
    fullScreenControl: false,
  }
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  })
  const [map, setMap] = useState(null)
  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center)
    map.fitBounds(bounds)

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={5}
      center={center}
      options={mapOptions}
      onLoad={onLoad}
      onUnmount={onUnmount}
    ></GoogleMap>
  ) : null
}

const ResearchArticles = () => {
  const { language } = useContext(LanguageContext)
  const content = {
    English: {
      title: "MORE INFORMATION ABOUT CASHEW",
      description: "",
    },
    Finnish: {
      title: "LISÄÄ TIETOA CASHEWISTA",
      description: "",
    },
  }

  return (
    <div className="text-content research-articles">
      <Row>
        <Col>
          <h2>
            {language === "Finnish"
              ? content.Finnish.title
              : content.English.title}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>
              <a
                href="http://www.jissn.com/content/10/1/13"
                rel="noopener noreferrer"
              >
                Cashew apple juice enhances fat utilization
              </a>
            </li>
            <li>
              <a
                href="https://www.sciencedirect.com/science/article/pii/S0278691505002243?casa_token=I_Z2BWspxiAAAAAA:Qxxo3UpaI5GkE0o24x4-Rarn8AVtbXbVqndPMFQGXR4-uVrhI_ps7Jqdvl0hU8TO7YogFQojog "
                rel="noopener noreferrer"
              >
                Cashew antioxidant capacity
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  )
}
const Product = () => {
  const { language } = useContext(LanguageContext)
  const [coordiante, setcoordiante] = useState(options[0].value)
  const [locations, setLocations] = useState([])
  const cities = Array.from(new Set(locations.map((loc) => loc.city)))
  const [addressess, setAddressess] = useState([])

  useEffect(() => {
    setLocations(data)
  }, [locations])



  const content = {
    English: {
      title: "Products",
      description: `Camarass Cashew Juice is produced from fresh ripe cashew fruits
      hand-picked from the Cashew trees. The Cashew fruit has both the
      nut and Cashew Apple. The Cashew Apple is soft, sweet and tender
      which is used to make the cashew Juice. It has very high content
      of Vitamins as well as antioxidant properties. It is an organic
      healthy nutritious fruit very popular in Brazil and tropical
      regions of the world. Camarass cashew fruit juice is available in
      K-Supermarkets. `,
    },
    Finnish: {
      title: "Tuotteet",
      description: `Camarass cashewhedelmämehu tehdään käsin poimituista ja tuoreista cashewhedelmistä. 
      Cashewhedelmä koostuu hedelmästä ja sen päällä kasvavasta pähkinästä . Cashewhedelmämehu puristetaan pehmeistä ja makeista cashewhedelmistä. 
      Cashewhedelmämehu sisältää runsaasti vitamiineja ja antioksidantteja. Terveellinen ja ravitseva cashewhedelmä kasvaa trooppisissa oloissa ja on hyvin suosittu mm. Brasiliassa. Tällä hetkellä Camarass cashewhedelmämehu on myynnissä K-Supermarketeissa.`,
    },
  }
  return (
    <Container>
      <Row>
        <Col>
          {" "}
          <h2 className="text-center">
            {language === "Finnish"
              ? content.Finnish.title
              : content.English.title}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col className="hero-text">
          <div className="text-content">
            <p className="text-justify">
              {language === "Finnish"
                ? content.Finnish.description
                : content.English.description}
            </p>
            <div className="product-image">
              <img src={camarassCashewCan} alt="Cashew fruit juit" />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3 p-3">
        <h2>{
          language === 'Finnish' ? 'Sijainnit' :'Locations'}</h2>
      
        {
          /*<Input
          className="mb-3 p-3"
          type="select"
          onChange={(e) => setcoordiante(e.target.value)}
        >
          {options.map((opt) => (
            <option value={opt.value}>{opt.label}</option>
          ))}
        </Input>
           <MapContainer coordiante={coordiante} />
           */
        }
        <div className="cities">
          {cities.map((city) => {
            const filteredAddresses  = locations.filter((loc) => loc.city === city)
            const numbers = filteredAddresses.length
            return (
              <div className="city" onClick = {() => setAddressess(filteredAddresses)}>
              <span>{city} ({numbers})</span>
              </div>
            )
          })}
        </div>
        {
          addressess.length > 0 && <div className="product-address">
          {
            addressess.map((address) => <div className="address">
            {address.zone && <h2>{address.zone}</h2>}
             <h3>{address.supermarket}</h3>
             <small>{address.streetName}, {address.zipcode} {address.city}</small>
            </div>)
          }
          </div>
        }

      </Row>

      <Row className="mb-5 mt-5">
        <ResearchArticles />
      </Row>
    </Container>
  )
}

export default Product
