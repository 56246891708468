import React from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import { Container } from "reactstrap";
import { Outlet } from "react-router-dom";

const Layout = () => (
  <Container fluid>
    <Header />
    <main>
      <Outlet />
    </main>
    <Footer />
  </Container>
);

export default Layout;
