import React from "react";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faYoutube,
  faTiktok,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Row, Col } from "reactstrap";

const Footer = () => (
  <footer>
    <Row className="footer-wrapper">
      <Col xs={12} sm={12} md={12} lg={6}>
        <small className="copyright">
          Copyright &copy; 2020 - {new Date().getFullYear()} | Camarass Cashew
          Juice
        </small>
      </Col>
      <Col xs={12} sm={12} md={12} lg={6}>
        <div className="social-media">
          <a
            href="https://www.facebook.com/camarasss"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faFacebookSquare}
              className="fa fa-facebook"
            />
          </a>

          <a
            href="https://www.instagram.com/camarasss/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} className="fa fa-instagram" />
          </a>
          <a href="https://www.youtube.com/channel/UCLpmdg1q5dXW8K_j06pZZjg">
            <FontAwesomeIcon icon={faYoutube} className="fa fa-youtube" />
          </a>
          <a
            href="https://www.tiktok.com/@camarass?lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTiktok} className="fa fa-tiktok" />
          </a>
        </div>
      </Col>
    </Row>
  </footer>
);

export default Footer;
