import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Product from "./pages/product/Product";
import Contacts from "./pages/contacts/Contacts";
import Story from "./pages/story/Story";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/products",
        element: <Product />
      },
      {
        path: "/stories",
        element: <Story />
      },
      {
        path: "/contacts",
        element: <Contacts />
      }
    ]
  }
]);
